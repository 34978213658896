import React from 'react'

const Markdown = ({ children }) => {
  if (typeof children === 'string') {
    children = [{ regular: children }]
  }
  return children.map((part, index) => {
    const regular =
      typeof part === 'string' ? part : part.regular ? part.regular : null
    return regular ? (
      <span key={index}>{regular}</span>
    ) : part.italic ? (
      <span key={part.italic} className='is-italic'>
        {part.italic}
      </span>
    ) : part.center ? (
      <span key={part.center} style={{ display: 'block', textAlign: 'center' }}>
        {part.center}
      </span>
    ) : part.bold ? (
      <strong key={part.bold}>{part.bold} </strong>
    ) : part.bigBold ? (
      <strong key={part.bigBold} className='is-size-5'>
        {part.bigBold}
      </strong>
    ) : part['center-bold'] ? (
      <strong
        key={part['center-bold']}
        style={{ display: 'block', textAlign: 'center' }}
      >
        {part['center-bold']}
      </strong>
    ) : part['center-bigBold'] ? (
      <strong
        key={part['center-bigBold']}
        style={{ display: 'block', textAlign: 'center' }}
        className='is-size-5'
      >
        {part['center-bigBold']}
      </strong>
    ) : part['center-bigBold-strikethrough'] ? (
      <strong
        key={part['center-bigBold-strikethrough']}
        style={{ display: 'block', textAlign: 'center' }}
        className='is-size-5'
      >
        <s>{part['center-bigBold-strikethrough']}</s>
      </strong>
    ) : part.strikethrough ? (
      <s key={part.strikethrough}>{part.strikethrough}</s>
    ) : part.newline ? (
      <br key={index} />
    ) : null
  })
}

export default Markdown
