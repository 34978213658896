import React, { useState } from 'react'
import clsx from 'clsx'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck,
  faShieldHeart,
  faAward,
  faHandHoldingDollar,
  faPeopleGroup,
  faGifts
} from '@fortawesome/free-solid-svg-icons'
import Layout from '../../components/Layout'
import Seo from '../../components/Seo'
import Markdown from '../../components/Markdown'
import Modal from '../../components/Modal'
import Testimony from '../../components/Testimony'
import BackToTopButton from '../../components/BackToTopButton'
import Link, { navigate } from '../../components/LinkWrapper'
import ContactForm from '../../components/ContactForm'
import ScrollOnAnimation from '../../components/ScrollOnAnimation'
import i18n from '../../locales/he.yaml'
import { fireEvent, useVideo } from '../../analytics'

const SecurityForcesBenefitsQuery = graphql`
  query SecurityForcesBenefitsQuery {
    image1: file(relativePath: { eq: "ברווזה-מפוסלת-בבצק-סוכר.JPG" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    image2: file(
      relativePath: {
        eq: "עוגה-מעוצבת-עם-ישראל-חי-עם-ילדים-ישראלים-מפוסלים-בבצק-סוכר.JPG"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
    rabbit: file(
      relativePath: { eq: "עוגה-מעוצבת-עם-פיסול-ארנב-בתלת-מימד-בבצק-סוכר.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    candies: file(
      relativePath: {
        eq: "עוגת-ממתקים-מעוצבת-בבצק-סוכר-עם-בובה-וחתול-מפוסלים-בעבודת-יד.JPG"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    safari: file(
      relativePath: {
        eq: "עוגה-מעוצבת-סופר-גבוהה-מקושטת-בדפי-אורז-ועם-חיות-הספארי-אריה-ג׳ירפה-קוף-פיל-מפוסלים-בבצק-סוכר.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    rinat: file(relativePath: { eq: "רינת-מחלב.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 128, layout: FIXED)
      }
    }
    mazal: file(relativePath: { eq: "מזל-פרגון.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 128, layout: FIXED)
      }
    }
    shaindy: file(relativePath: { eq: "שיינדי-ברנט.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 128, layout: FIXED)
      }
    }
    survival: file(
      relativePath: {
        eq: "קארין-עמנואל-עם-עוגת-הישרדות-ענקית-מרחפת-בגובה-מטר-מפוסלת-בבצק-סוכר-בעבודת-יד.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    halloween: file(
      relativePath: {
        eq: "קארין-עמנואל-עם-עוגת-בית-מכושף-ענקי-להלווין-ליל-כל-הקדושים-מעוצבת-בבצק-סוכר.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
    eragon: file(
      relativePath: {
        eq: "עוגת-אראגון-מעוצבת-בבצק-סוכר-בצורת-ספרים-ודרקון-ענק.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
    genie: file(
      relativePath: { eq: "קארין-עמנואל-עם-עוגת-גי׳ני-של-אלאדין-בתלת-מימד.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
  }
`

const SecurityForcesBenefitsTemplate = ({ pathname, data, product }) => {
  const [isShowMiluimBenefitsInfo, setIsShowMiluimBenefitsInfo] =
    useState(false)

  const iframeRef = useVideo(product)

  const {
    image1,
    image2,
    rabbit,
    candies,
    safari,
    rinat,
    mazal,
    shaindy,
    survival,
    halloween,
    eragon,
    genie
  } = data

  const IMAGES = [null, image1]

  const IMAGES_COURSES = {
    'עוגת ארנבים': rabbit,
    'עוגת ממתקים': candies,
    'עוגת ספארי': safari
  }

  const courses = i18n.courses.courses.filter((course) =>
    Object.keys(IMAGES_COURSES).includes(course.name)
  )

  const TESTIMONIALS_IMAGES = {
    'רינת מחלב': rinat,
    'מזל פרגון': mazal,
    'שיינדי ברנט': shaindy
  }

  const INPIRATION_IMAGE = [halloween, eragon, genie]

  const CTA = () => (
    <a
      href='#selection'
      className='button is-nude is-medium'
      onClick={() =>
        fireEvent('InternalClick', product, {
          to: '#selection'
        })
      }
    >
      {i18n.securityForcesBenefits.join}
    </a>
  )

  return (
    <>
      {isShowMiluimBenefitsInfo && (
        <Modal isOpen onClose={() => setIsShowMiluimBenefitsInfo(false)}>
          <div className='box'>
            <div className='columns content is-flex-direction-column-touch'>
              <div className='column'>
                <h4 className='has-text-centered'>
                  {i18n.securityForcesBenefits.miluimBenefits.info.title}
                </h4>
                <p>
                  <Markdown>
                    {
                      i18n.securityForcesBenefits.miluimBenefits.info
                        .description
                    }
                  </Markdown>
                </p>
                <p className='is-margin-top-4'>
                  <a
                    href={
                      i18n.securityForcesBenefits.miluimBenefits.info.cta.link
                    }
                    className='button is-link-ka is-text pl-0'
                    target='_blank'
                    rel='noreferrer'
                  >
                    {i18n.securityForcesBenefits.miluimBenefits.info.cta.text}
                  </a>
                </p>
                <p>
                  <Markdown>
                    {i18n.securityForcesBenefits.miluimBenefits.info.suffix}
                  </Markdown>
                </p>
                <p className='has-text-centered'>
                  <button
                    className='button is-text'
                    onClick={() => setIsShowMiluimBenefitsInfo(false)}
                  >
                    {i18n.securityForcesBenefits.miluimBenefits.info.back}
                  </button>
                </p>
              </div>
            </div>
          </div>
        </Modal>
      )}
      <BackToTopButton selectorId='security-forces-benefits' />
      <div className='hero has-navbar-fixed-top'>
        <div className='hero-body is-padding-top-6 is-padding-bottom-6'>
          <div className='container'>
            <nav className='breadcrumb' aria-label='breadcrumbs'>
              <ul>
                <li>
                  <Link to={'/academy/'}>{i18n.header.academy}</Link>
                </li>
                <li className='is-active'>
                  <Link to={pathname} aria-current='page'>
                    {i18n.header.securityForcesBenefits}
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className='hero has-background-nude-lighter'>
        <div className='hero-body'>
          <div className='container content has-text-centered'>
            <div className='columns is-centered'>
              <div className='column is-10 is-full-touch'>
                <h1 className='has-text-nude is-size-4 white-space-normal-mobile is-margin-top-0'>
                  {i18n.securityForcesBenefits.subtitle}
                </h1>
                <h2 className='is-size-4 is-margin-bottom-6 white-space-normal-mobile'>
                  {i18n.securityForcesBenefits.title}
                </h2>
                <div className='columns is-mobile is-multiline is-centered is-vcentered is-margin-bottom-6'>
                  {i18n.securityForcesBenefits.benefits.map((benefit) => (
                    <div key={benefit} className='column is-narrow'>
                      <div className='tags has-addons'>
                        <span className='tag is-medium'>
                          <FontAwesomeIcon
                            icon={faCheck}
                            className='has-text-nude'
                            size='sm'
                          />
                        </span>
                        <span className='tag is-medium is-nude-light'>
                          {benefit}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
                <p className='is-centered is-italic is-size-6 is-margin-bottom-6'>
                  {i18n.securityForcesBenefits.deadline}
                </p>
                <p>
                  <CTA />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ScrollOnAnimation>
        <div className='hero has-background-nude-light'>
          <div className='hero-body'>
            <div className='container content has-text-centered'>
              <div className='columns is-centered'>
                <div className='column is-6 is-full-touch'>
                  <FontAwesomeIcon
                    icon={faShieldHeart}
                    className='has-text-nude is-margin-bottom-4'
                    size='2x'
                  />
                  <div className='is-margin-bottom-4'>
                    <h3>{i18n.securityForcesBenefits.audience.title}</h3>
                  </div>
                  <p>
                    <Markdown>
                      {i18n.securityForcesBenefits.audience.description}
                    </Markdown>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ScrollOnAnimation>
      {i18n.securityForcesBenefits.bullets.map((bullet, index) => (
        <div
          key={index}
          className='hero'
          id={index === 1 ? 'security-forces-benefits' : undefined}
        >
          <div className='hero-body'>
            <div className='container content'>
              <div
                className={clsx(
                  'columns is-mobile is-multiline is-centered is-vcentered',
                  {
                    'direction-row-reverse': index % 2 === 1
                  }
                )}
              >
                <div
                  className={clsx(
                    'column is-full-mobile is-8-tablet is-6-desktop',
                    {
                      'is-offset-1-widescreen': index % 2 === 1
                    }
                  )}
                >
                  <ScrollOnAnimation key={bullet.title}>
                    <div className='columns is-flex-direction-column-touch'>
                      <div className='column'>
                        <h4 className='has-text-centered-touch'>
                          {bullet.title}
                        </h4>
                        <p>
                          <Markdown>{bullet.description}</Markdown>
                        </p>
                        {bullet.cta && (
                          <p className='is-margin-top-4'>
                            <a
                              href={bullet.cta.link}
                              className='button is-link-ka is-text pl-0'
                              target='_blank'
                              rel='noreferrer'
                            >
                              {bullet.cta.text}
                            </a>
                          </p>
                        )}
                        {bullet.suffix && (
                          <p>
                            <Markdown>{bullet.suffix}</Markdown>
                          </p>
                        )}
                      </div>
                    </div>
                  </ScrollOnAnimation>
                </div>
                <ScrollOnAnimation>
                  <div
                    className={clsx(
                      'column is-full-real-mobile is-10-mobile is-7-tablet is-6-desktop is-5-widescreen is-margin-top-4-touch',
                      {
                        'is-offset-1-widescreen':
                          index % 2 === 0 && !bullet.video,
                        'is-10-mobile': !bullet.video,
                        'is-12-mobile': bullet.video,
                        'is-7-tablet': !bullet.video,
                        'is-10-tablet': bullet.video,
                        'is-5-widescreen': !bullet.video,
                        'is-6-widescreen': bullet.video
                      }
                    )}
                  >
                    {bullet.image && (
                      <GatsbyImage
                        image={getImage(IMAGES[index])}
                        title={bullet.image.title}
                        alt={bullet.image.alt}
                        className='live-image'
                        style={{
                          maxWidth: getImage(IMAGES[index]).width,
                          margin: '0 auto' // used to center the image
                        }}
                        loading='eager'
                      />
                    )}
                    {bullet.video && (
                      <figure
                        className='image is-16by9'
                        style={{
                          marginRight: 0,
                          marginLeft: 0,
                          marginBottom: 0
                        }}
                      >
                        <iframe
                          className='has-ratio'
                          width='640'
                          height='360'
                          src={`https://player.vimeo.com/video/${bullet.video.vimeoId}?h=826545297f&badge=0&autopause=0player_id=0&app_id=58479`}
                          frameBorder='0'
                          allow='autoplay; fullscreen; picture-in-picture'
                          ref={iframeRef}
                        />
                      </figure>
                    )}
                    <div className='is-flex justify-center content'>
                      <p className='has-text-centered has-text-gold-black'>
                        {(bullet.image || bullet.video).title}
                      </p>
                    </div>
                    {bullet.video && (
                      <script src='https://player.vimeo.com/api/player.js'></script>
                    )}
                  </div>
                </ScrollOnAnimation>
              </div>
              <ScrollOnAnimation>
                <div className='has-text-centered is-margin-top-7'>
                  <CTA />
                </div>
              </ScrollOnAnimation>
            </div>
          </div>
        </div>
      ))}
      <ScrollOnAnimation>
        <div className='hero'>
          <div className='hero-body'>
            <div className='container content has-text-centered'>
              <div className='columns is-centered'>
                <div className='column is-6 is-full-touch'>
                  <FontAwesomeIcon
                    icon={faHandHoldingDollar}
                    className='has-text-nude is-margin-bottom-4'
                    size='2x'
                  />
                  <div className='is-margin-bottom-4'>
                    <h3>{i18n.securityForcesBenefits.miluimBenefits.title}</h3>
                  </div>
                  <p className='is-margin-bottom-4'>
                    {i18n.securityForcesBenefits.miluimBenefits.description}
                  </p>
                  <p className='is-margin-bottom-4'>
                    <button
                      className='button is-link-ka is-text'
                      onClick={() => setIsShowMiluimBenefitsInfo(true)}
                    >
                      {i18n.securityForcesBenefits.miluimBenefits.cta}
                    </button>
                  </p>
                  <div className='column'>
                    <GatsbyImage
                      image={getImage(image2)}
                      title={
                        i18n.securityForcesBenefits.miluimBenefits.image.title
                      }
                      alt={i18n.securityForcesBenefits.miluimBenefits.image.alt}
                      className='live-image'
                      style={{
                        maxWidth: getImage(image2).width,
                        margin: '0 auto' // used to center the image
                      }}
                      loading='eager'
                    />
                    <div className='is-flex justify-center content'>
                      <p className='has-text-centered has-text-gold-black'>
                        {i18n.securityForcesBenefits.miluimBenefits.image.title}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ScrollOnAnimation>
      <ScrollOnAnimation>
        <div className='hero has-background-nude-light'>
          <div className='hero-body'>
            <div className='container content has-text-centered'>
              <div className='columns is-centered'>
                <div className='column is-6 is-full-touch'>
                  <FontAwesomeIcon
                    icon={faAward}
                    className='has-text-nude is-margin-bottom-4'
                    size='2x'
                  />
                  <div className='is-margin-bottom-4'>
                    <h3>{i18n.securityForcesBenefits.responsibility.title}</h3>
                  </div>
                  <p className='is-margin-bottom-6'>
                    {i18n.securityForcesBenefits.responsibility.description}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ScrollOnAnimation>
      <ScrollOnAnimation>
        <div className='hero has-background-nude-lighter'>
          <div className='hero-body'>
            <div className='container content has-text-centered'>
              <div className='columns is-centered'>
                <div className='column is-6 is-full-touch'>
                  <FontAwesomeIcon
                    icon={faPeopleGroup}
                    className='has-text-nude is-margin-bottom-4'
                    size='2x'
                  />
                  <div className='is-margin-bottom-4'>
                    <h3>{i18n.securityForcesBenefits.limit.title}</h3>
                  </div>
                  <p>
                    <Markdown>
                      {i18n.securityForcesBenefits.limit.description}
                    </Markdown>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ScrollOnAnimation>
      <ScrollOnAnimation>
        <div id='selection' className='hero'>
          <div className='hero-body'>
            <div className='container'>
              <div className='columns is-centered'>
                <div className='column is-6 is-full-touch content has-text-centered'>
                  <FontAwesomeIcon
                    icon={faGifts}
                    className='has-text-nude is-margin-bottom-4'
                    size='2x'
                  />
                  <div className='is-margin-bottom-4'>
                    <h3>{i18n.securityForcesBenefits.selection.title}</h3>
                  </div>
                  <p>
                    <Markdown>
                      {i18n.securityForcesBenefits.selection.description}
                    </Markdown>
                  </p>
                </div>
              </div>
              <div className='columns is-multiline is-centered is-margin-top-4'>
                {courses.map((_course, index) => (
                  <ScrollOnAnimation key={index}>
                    <div className='column is-4-widescreen is-4-desktop is-6-tablet is-10-mobile is-offset-1-mobile'>
                      <div
                        className='card is-clickable'
                        style={{ height: '100%' }}
                        onClick={() =>
                          navigate(
                            `/academy/courses/${_course.name.replaceAll(
                              ' ',
                              '-'
                            )}/`,
                            { state: { isMiluimBenefits: true } }
                          )
                        }
                      >
                        <div className='card-image'>
                          <GatsbyImage
                            image={getImage(IMAGES_COURSES[_course.name])}
                            title={_course.image.title}
                            alt={_course.image.alt}
                            style={{
                              maxWidth: getImage(IMAGES_COURSES[_course.name])
                                .width,
                              margin: '0 auto' // used to center the image
                            }}
                          />
                        </div>
                        <div className='has-text-centered p-2 is-margin-bottom-0'>
                          <div className='tag is-medium is-nude-light'>
                            {i18n.securityForcesBenefits.levels[_course.level]}
                          </div>
                        </div>
                        <div className='card-footer is-flex-grow-1'>
                          <Link
                            className='card-footer-item has-text-centered'
                            to={`/academy/courses/${_course.name.replaceAll(
                              ' ',
                              '-'
                            )}/`}
                            onClick={(e) => e.stopPropagation()}
                            state={{ isMiluimBenefits: true }}
                          >
                            {i18n.securityForcesBenefits.moreDetailsPrefix}{' '}
                            {_course.name}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </ScrollOnAnimation>
                ))}
              </div>
            </div>
          </div>
        </div>
      </ScrollOnAnimation>
      <ScrollOnAnimation>
        <div className='hero has-background-nude-light'>
          <div className='hero-body'>
            <div className='container'>
              <div className='columns is-centered'>
                <div className='column is-narrow'>
                  <ContactForm
                    product={product}
                    name='security-forces-benefits'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ScrollOnAnimation>
      {i18n.securityForcesBenefits.testimonials && (
        <>
          <div className='hero'>
            <div className='hero-body'>
              <div className='container content'>
                <ScrollOnAnimation>
                  <div className='has-text-centered is-margin-bottom-6'>
                    <h3>{i18n.securityForcesBenefits.testimonials.title}</h3>
                  </div>
                </ScrollOnAnimation>
                {i18n.securityForcesBenefits.testimonials.testimonials.map(
                  (testimony, index) => (
                    <ScrollOnAnimation key={testimony.title}>
                      <>
                        <Testimony
                          testimony={testimony}
                          image={TESTIMONIALS_IMAGES[testimony.name]}
                          expand={
                            i18n.securityForcesBenefits.testimonials.expand
                          }
                          isBottomHR={
                            index <
                            i18n.securityForcesBenefits.testimonials
                              .testimonials.length -
                              2
                          }
                        />
                      </>
                    </ScrollOnAnimation>
                  )
                )}
              </div>
            </div>
          </div>
        </>
      )}
      <ScrollOnAnimation>
        <div className='hero'>
          <div className='hero-body'>
            <div className='container content has-text-centered'>
              <CTA />
            </div>
          </div>
        </div>
      </ScrollOnAnimation>
      <div className='hero has-background-nude-lighter'>
        <div className='hero-body'>
          <div className='container'>
            <div className='columns is-mobile is-multiline is-centered'>
              <ScrollOnAnimation>
                <div className='column content is-margin-bottom-0 is-full-mobile is-10-tablet is-6-desktop'>
                  <h3>{i18n.academy.about.title}</h3>
                  <p>{i18n.academy.about.description}</p>
                </div>
              </ScrollOnAnimation>
              <ScrollOnAnimation>
                <div className='column is-4-fullhd is-5-widescreen is-half-tablet is-10-mobile is-offset-2-fullhd is-offset-1-widescreen'>
                  <GatsbyImage
                    image={getImage(survival)}
                    title={i18n.academy.about.mainImage.title}
                    alt={i18n.academy.about.mainImage.alt}
                    className='live-image'
                    style={{
                      maxWidth: getImage(survival).width,
                      margin: '0 auto' // used to center the image
                    }}
                  />
                  <div className='is-flex justify-center content'>
                    <p className='has-text-centered has-text-gold-black'>
                      {i18n.academy.about.mainImage.title}
                    </p>
                  </div>
                </div>
              </ScrollOnAnimation>
              <div className='column content is-12 is-gapless-mobile'>
                <ScrollOnAnimation>
                  <p>{i18n.academy.about.inspiration}</p>
                </ScrollOnAnimation>
                <div className='columns is-mobile is-multiline is-centered is-1-mobile'>
                  {INPIRATION_IMAGE.map((image, index) => (
                    <ScrollOnAnimation key={index}>
                      <div className='column is-one-third-desktop is-half-tablet is-10-mobile has-text-centered'>
                        <GatsbyImage
                          image={getImage(image)}
                          title={i18n.academy.about.images[index].title}
                          alt={i18n.academy.about.images[index].alt}
                          className='live-image'
                          style={{
                            maxWidth: getImage(image).width,
                            margin: '0 auto' // used to center the image
                          }}
                          loading='eager'
                        />
                        <div className='is-flex justify-center'>
                          <p className='has-text-centered has-text-gold-black'>
                            {i18n.academy.about.images[index].title}
                          </p>
                        </div>
                      </div>
                    </ScrollOnAnimation>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ScrollOnAnimation>
        <div className='hero has-background-nude-lighter'>
          <div className='hero-body'>
            <div className='container content has-text-centered'>
              <CTA />
            </div>
          </div>
        </div>
      </ScrollOnAnimation>
    </>
  )
}

const SecurityForcesBenefits = ({ location: { pathname } }) => {
  const product = {
    category: i18n.securityForcesBenefits.metadata.title,
    name: i18n.securityForcesBenefits.metadata.title
  }
  const data = useStaticQuery(SecurityForcesBenefitsQuery)
  return (
    <Layout product={product}>
      <Seo
        metadata={i18n.securityForcesBenefits.metadata}
        pathname={pathname}
        og={{
          image: getSrc(data.image2),
          alt: i18n.securityForcesBenefits.mainImage.alt
        }}
        schemaMarkup={{
          breadcrumbs: [
            { name: i18n.header.academy, path: '/academy/' },
            {
              name: i18n.header.securityForcesBenefits,
              path: '/academy/security-forces-benefits/'
            }
          ]
        }}
      />
      <SecurityForcesBenefitsTemplate
        pathname={pathname}
        data={data}
        product={product}
      />
    </Layout>
  )
}

export default SecurityForcesBenefits
