import React, { useState, useReducer } from 'react'
import clsx from 'clsx'
import { fireEvent } from '../analytics'
import { encode, fetchWitRetry } from '../utils'
import i18n from '../locales/he.yaml'

function reducer(state, action) {
  if (action.type === 'INIT') return {}
  const newState = { ...state }
  if (!action.value) {
    delete newState[action.key]
  } else newState[action.key] = action.value
  return newState
}

const ContactForm = ({ product, name }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isDone, setIsDone] = useState(false)
  const [registrationData, dispatchRegistrationData] = useReducer(reducer, {})
  return isDone ? (
    <div className='card'>
      <div className='card-content'>
        <p className='has-text-centered'>{i18n.courses.registration.sent}</p>
      </div>
    </div>
  ) : (
    <div className='card'>
      <header className='card-header direction-column items-center'>
        <h3 className='card-header-title is-centered is-size-4'>
          {i18n.courses.registration.title}
        </h3>
        <p className='is-padding-left-6 is-padding-right-6 is-padding-bottom-4'>
          {i18n.courses.registration.subtitle}
        </p>
      </header>
      <div className='card-content'>
        <form
          name={name}
          method='POST'
          action={`/${name}/thanks/`}
          data-netlify='true'
          data-netlify-honeypot='bot-field'
          onSubmit={async (e) => {
            e.preventDefault()
            setIsLoading(true)
            await fireEvent('Lead', product)
            const form = e.target
            try {
              await fetchWitRetry('/', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: encode({
                  'form-name': form.getAttribute('name'),
                  ...registrationData
                })
              })
              setIsLoading(false)
              dispatchRegistrationData({
                type: 'INIT'
              })
              setIsDone(true)
              // for some reason without setTimeout the first time it triggers without opening the modal
              await new Promise((resolve) => {
                setTimeout(resolve, 5000)
              })
              setIsDone(false)
            } catch {
              void 0
            }
          }}
        >
          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <input type='hidden' name='form-name' value={name} />
          <div className='field' hidden>
            <input
              name='bot-field'
              onChange={(e) =>
                dispatchRegistrationData({
                  key: 'bot-field',
                  value: e.target.value
                })
              }
            />
          </div>
          <div className='field'>
            <label className='label' htmlFor='input-name'>
              {i18n.courses.registration.name.title}
            </label>
            <div className='control'>
              <input
                id='input-name'
                className='input'
                type='text'
                name='name'
                value={registrationData.name || ''}
                placeholder={i18n.courses.registration.name.placeholder}
                onChange={(e) => {
                  dispatchRegistrationData({
                    key: 'name',
                    value: e.target.value
                  })
                  e.target.setCustomValidity('')
                }}
                required
                onInvalid={(e) =>
                  e.target.setCustomValidity(
                    i18n.courses.registration.errors.name
                  )
                }
              />
            </div>
          </div>
          <div className='field'>
            <label className='label' htmlFor='input-phone'>
              {i18n.courses.registration.phone.title}
            </label>
            <div className='control'>
              <input
                id='input-phone'
                className='input is-family-secondary'
                type='tel'
                pattern='[0-9]{9,10}'
                name='phone'
                value={registrationData.phone || ''}
                placeholder={i18n.courses.registration.phone.placeholder}
                onChange={(e) => {
                  dispatchRegistrationData({
                    key: 'phone',
                    value: e.target.value
                  })
                  if (
                    new RegExp(e.target.getAttribute('pattern')).test(
                      e.target.value
                    )
                  ) {
                    e.target.setCustomValidity('')
                  }
                }}
                required
                onInvalid={(e) =>
                  e.target.setCustomValidity(
                    i18n.courses.registration.errors.phone
                  )
                }
              />
            </div>
            <p className='help'>{i18n.courses.registration.phone.hint}</p>
          </div>
          <button
            className={clsx('button is-fullwidth is-nude is-medium', {
              'is-loading': isLoading
            })}
            type='submit'
            disabled={isLoading}
          >
            {i18n.courses.registration.submit}
          </button>
        </form>
      </div>
    </div>
  )
}

export default ContactForm
